html,
body {
  scroll-behavior: smooth;
}

.nav-bar {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 8vh;
  width: 100vw;
  animation: fadeIn 1.5s ease-in-out;
  margin-bottom: 50px;
  min-height: 5vh;
  max-height: 10vh;
}

.logo-div {
  position: absolute;
  left: 4%;
  top: 0%;
  height: 100%;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: left;
}

.logo-div-vertical {
  position: absolute;
  left: 4%;
  top: 0%;
  height: 8vh;
  width: 70vw;
  display: flex;
  align-items: center;
  justify-content: left;
}

.logo {
  font-family: Tahoma, 'Helvetica Neue', Arial, sans-serif; 
  font-size: 25px;
  color: white;
  text-decoration: none;

  text-shadow: -1px -1px 0 #000, /* Left and top shadow */ 1px -1px 0 #000,
    /* Right and top shadow */ -1px 1px 0 #000,
    /* Left and bottom shadow */ 1px 1px 0 #000; /* Right and bottom shadow */
}

.nav-list {
  position: absolute;
  top: 0%;
  height: 100%;
  right: 1.5%;
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: right;
}

.nav-list .nav-page.active {
  text-decoration: underline;
}

.nav-page {
  font-family: Tahoma, 'Helvetica Neue', Arial, sans-serif; 
  font-size: 25px;
  color: white;
  text-decoration: none;
  padding: 13px;
  transition: background-color 0.3s;

  text-shadow: -1px -1px 0 #000, /* Left and top shadow */ 1px -1px 0 #000,
    /* Right and top shadow */ -1px 1px 0 #000,
    /* Left and bottom shadow */ 1px 1px 0 #000; /* Right and bottom shadow */
}

.active-c {
  text-decoration: underline;
}

.nav-page:hover {
  background-color: #000;
  border-radius: 10%;
  transition: background-color 0.7s ease;
}

.resume {
  font-family: Tahoma, 'Helvetica Neue', Arial, sans-serif; 
  font-size: 25px;
  color: white;
  text-decoration: none;
  padding: 13px;
  margin-left: 10px;
  margin-right: 10px;
  border: white 2px solid;
  border-radius: 15%;
  transition: background-color 0.3s;

  text-shadow: -1px -1px 0 #000, /* Left and top shadow */ 1px -1px 0 #000,
    /* Right and top shadow */ -1px 1px 0 #000,
    /* Left and bottom shadow */ 1px 1px 0 #000; /* Right and bottom shadow */
}

.resume:hover {
  background-color: #000;
  border-radius: 10%;
  transition: background-color 0.7s ease;
}

.nav-icon-div {
  position: absolute;
  top: 0;
  right: 0;
  height: 8vh;
  width: 20vw;
  padding-right: 2vw;
  display: flex;
  justify-content: right;
}

.button-bkg {
  background-color: transparent;
  border: none;
}

.nav-icon {
  position: absolute;
  right: 1.5%;
  top: 1.5%;
  height: 100%;
  width: auto;
}

.vertical-nav-bar {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(37, 53, 81, 0.97);
  z-index: 1000000;
  animation: fadeIn 0.7s ease-in-out;
}

.vnav-button {
  background-color: transparent;
  height: 50vh;
  width: auto;
  border: none;
  position: absolute;
  top: 2.5%;
  right: 2.5%;
}

.nav-icon-exit {
  position: absolute;
  top: 2.5%;
  right: 2.5%;
  height: 7vh;
  width: auto;
}

.nav-v-list {
  width: 100%;
  height: 88vh;
  padding-top: 12vh;
  display: flex;
  justify-content: center;
}

.nav-v-list-ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  line-height: 2.3;
}

.nav-v-list-li {
  font-size: 30px;
  color: white;
  text-align: center;
}

.nl {
  text-decoration: none;
  display: block;
  width: 100%;
}