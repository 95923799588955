html,
body {
  position: fixed;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  scroll-behavior: smooth;
  overflow-y: auto;
  font-family: Tahoma, 'Helvetica Neue', Arial, sans-serif; 
}
