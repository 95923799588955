/* .bg {
  position: absolute;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
} */

.bg {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.bg {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.exp {
  flex-grow: 1;
}

.footer-container {
  margin-top: auto;
}

.nav-background {
  min-height: fit-content;
  height: 8vh;
  background-color: #253551;
}

@media (max-height: 780px) {
  .nav-background {
    padding-top: 1vh;
    min-height: fit-content;
    height: 10vh;
    background-color: #253551;
  }
}

@media (max-height: 700px) {
  .nav-background {
    padding-top: 2vh;
    min-height: fit-content;
    height: 12vh;
    background-color: #253551;
  }
}

.exp-header {
  position: relative;
  padding-top: 2vh;
  padding-bottom: 1vh;
  padding-left: 2vw;
  padding-right: 2vw;
  min-height: 9vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.exp-header-h1 {
  font-size: 32px;
  width: 100%;
  text-align: center;
}

.exp {
  position: relative;
  height: 210vh;
  width: 100%;
  display: flex;
  align-content: center;
  justify-items: center;
  animation: fadeIn 1.5s ease-in-out;
}

.rows {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
}

.columns {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  z-index: 100;
}

.column {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pic {
  position: absolute;
  top: 5vh;
  height: 40vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  padding-left: 2vw;
  padding-right: 2vw;
}

.date {
  position: absolute;
  top: 45vh;
  width: 100%;
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 2vw;
  padding-right: 2vw;
}

.date-h3 {
  width: 100%;
  text-align: center;
}

.company {
  position: absolute;
  top: 50vh;
  width: 100%;
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 1vw;
  padding-right: 1vw;
}

.company-h2 {
  width: 100%;
  text-align: center;
}

.desc {
  position: absolute;
  top: 55vh;
  width: 100%;
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 2vw;
  padding-right: 2vw;
}

.position-p {
  width: 100%;
  text-align: center;
}

.image {
  height: 100%;
  width: auto;
  background-color: white;
  border: none;
}

.image:hover {
  opacity: 0.7;
}

.learn {
  position: absolute;
  top: 60vh;
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.link {
  background-color: rgb(37, 53, 81);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 15px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s;
}

.link:hover {
  background-color: rgba(37, 53, 81, 0.8);
}

@media (max-width: 980px) {
  .rows {
    display: grid;
    grid-template-columns: 1fr; /* One column */
    grid-template-rows: repeat(3, 1fr); /* Six rows */
  }

  .columns {
    display: grid;
    grid-template-columns: none;
    grid-template-rows: repeat(2, 1fr);
  }

  .column {
    height: 100%;
    width: 100%;
    display: flex;
  }

  .pic {
    position: absolute;
    top: 0vh;
    height: 100%;
    max-width: 45%;
    left: 0;
    display: block;
    align-items: center;
    z-index: 1;
  }

  .image {
    padding-left: 2%;
    margin-top: 1.75vh;
    height: 30vh;
    width: auto;
    max-width: 100%;
    max-height: auto;
  }

  .company {
    width: 53%;
    position: absolute;
    top: 0;
    height: 7vh;
    right: 0;
    padding-top: 1.75vh;
    display: flex;
  }

  .company-h2 {
    width: 100%;
    text-align: center;
  }

  .date {
    position: absolute;
    width: 53%;
    right: 0;
    top: 14vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;
  }

  .date-h3 {
    width: 100%;
    text-align: center;
  }

  .desc {
    position: absolute;
    width: 53%;
    right: 0;
    top: 8vh;
    height: 5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1vh;
  }

  .position-p {
    width: 100%;
    text-align: center;
  }

  .learn {
    position: absolute;
    width: 53%;
    right: 0;
    top: 25vh;
    height: 3vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1vh;
  }

  .link {
    background-color: rgb(37, 53, 81);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 15px;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    transition: background-color 0.3s;
  }
}
