.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(124, 120, 120, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(3.5px);
  z-index: 10000;
  height: 1000vh;
  animation: fadeIn 0.5s ease-in-out;
}

.modal-content {
  position: fixed;
  top: 10vh;
  min-height: 80vh;
  left: 10%;
  width: 80%;
  background-color: ghostwhite;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 100000;
  animation: fadeIn 0.5s ease-in-out;
  overflow: auto; /* Add scroll if content overflows */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.outer {
  position: absolute;
  width: 100%;
  height: 100%;
}

.headline-div {
  position: relative;
  top: 0vh;
  height: 8vh;
  left: 15%;
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.company-name {
  margin-top: 2vh;
  margin-bottom: 2vh;
  text-align: center;
  font-size: 34px;
}

.content {
  display: flex;
  flex-direction: row;
}

.role-h1 {
  margin-left: 2vw;
  font-size: 22px;
  margin-bottom: 2vh;
}

.responsibilities {
  margin: 0;
  padding: 0;
  min-height: fit-content;
  width: 60%;
  padding-top: 1vh;
  padding-left: 2vw;
  display: block;
}

.responsibilities-h1 {
  font-size: 22px;
  padding-bottom: 2vh;
}

.accomplishments-ul {
  line-height: 1.5;
  padding-right: 3vw;
  padding-left: 1vw;
}

.accomplishments-li {
  padding-bottom: 2vh;
  font-size: 18px;
}

.media-content {
  margin: 0;
  padding: 0;
  height: 65vh;
  width: 40%;
  padding-top: 1vh;
  display: block;
}

.media-content-div {
  padding-bottom: 3vh;
  width: 100%;
  display: block;
}

.media-content-div a {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.media-content-div a:hover {
  opacity: 0.5;
}

.media-content-div p {
  width: 100%;
  padding-top: 1.5vh;
  text-align: center;
}

.intern-picture-div {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.presentation-picture {
  width: 90%;
  height: auto;
}

.ex-button {
  background-color: transparent;
  border: none;
}

.ex-button-img {
  height: 4vh;
  width: auto;
}

.button-div {
  position: absolute;
  top: 1vh;
  right: 1vw;
  width: 5vw;
  display: flex;
  justify-content: right;
  align-items: center;
}

.button-div:hover {
  opacity: 0.5;
}

@media (max-width: 620px) {
  .headline-div {
    margin-top: 2vh;
    margin-bottom: 3vh;
    height: fit-content;
    width: 90vw;
    left: 0;
  }

  .company-name {
    margin: 0;
  }

  .content {
    flex-direction: column;
  }

  /* .role {
    margin-top: 4vh;
  } */

  .role-h1 {
    text-align: center;
  }

  .responsibilities {
    /* margin: 0; */
    padding: 0;
    min-height: fit-content;
    width: 84vw;
    padding-top: 1vh;
    margin-left: 6vw;
    display: block;
  }

  .modal-content {
    width: 90vw;
    left: 5vw;
  }

  .presentation-picture {
    width: 85vw;
    height: auto;
    display: flex;
    justify-content: flex-start;
    margin-left: 0;
  }

  .media-content {
    width: 90vw;
  }
}
