.footer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 12vh;
  background-color: black;
  padding-bottom: 5vh;
}

@media (max-width: 600px) {
  .footer {
    height: 14vh;
  }
}

.footer-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.email {
  width: 100%;
  height: 6vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2vh;
}

.socials {
  position: relative;
  width: 100%;
  height: 6vh;
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-email {
  padding-top: 3vh;
  color: white;
  text-decoration: none;
  width: 100%;
  text-align: center;
}

.social-element1 {
  text-decoration: none;
  padding-top: 2vh;
  color: white;
  padding-right: 12px;
}

.social-element2 {
  padding-left: 12px;
  text-decoration: none;
  padding-top: 2vh;
  color: white;
}
