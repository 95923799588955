html,
body {
  background-color: white;
  scroll-behavior: smooth;
  font-family: apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.app {
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
}

.top-container {
  position: relative;
  height: 80vh;
  width: 100%;
  background-image: url("../../Media/Blair-Arch.jpg");
  background-size: cover;
  background-position: center;
  animation: fadeIn 1.5s ease-in-out;
}

.tc-writing {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100vw;
  left: 4%;
  bottom: 10%;
  animation: fadeIn 1.5s ease-in-out;
}

.hello {
  line-height: 1.6;
  color: white;
  font-weight: bold;
  font-size: 60px;
  padding-bottom: 2vh;
  text-shadow: -2px -2px 0 #000, /* Left and top shadow */ 2px -2px 0 #000,
    /* Right and top shadow */ -2px 2px 0 #000,
    /* Left and bottom shadow */ 2px 2px 0 #000; /* Right and bottom shadow */
}

.about-button-div {
  height: 8vh;
  min-width: 13vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
}

.about-button-div a {
  width: 100%;
  color: rgb(0, 0, 0);
  border: none;
  font-size: 24px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s;
  font-weight: 600;
}

.about-button-div:hover {
  background-color: rgba(255, 255, 255, 0.75);
}

@media (max-width: 820px) {
  .top-container {
    position: relative;
    height: 80vh;
    width: 100%;
    background-image: url("../../Media/Tiger-Pic-Iphone.jpg");
    background-size: cover;
    background-position: center;
    animation: fadeIn 1.5s ease-in-out;
  }

  .hello {
    font-size: 40px;
    padding-bottom: 0vh;
  }

  .about-button-div {
    min-width: 30vw;
    width: auto;
    margin-top: 20px;
  }

  .about-button-div a {
    font-size: 18px;
  }
}

.about-container {
  min-height: 22vh;
  width: 100%;
  background-color: #e0e0db;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.about-div {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  animation: fadeIn 1.5s ease-in-out;
}

.about-text {
  align-self: center;
  line-height: 1.6;
  color: black;
  font-weight: bold;
  font-size: 28px;
  padding: 50px;
}

@media (max-height: 600px) {
  .about-text {
    font-size: 24px;
  }
}

@media (max-width: 400px) {
  .about-text {
    font-size: 24px;
  }
}

.exp-container {
  min-height: 62vh;
  width: 100%;
}

.exp-div {
  min-height: 62vh;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.writing {
  width: 60%;
  margin-top: 2vh;
}

.title {
  width: 100%;
  min-height: 5vh;
  padding-top: 5vh;
  padding-bottom: 5vh;
  padding-left: 4vw;
  font-size: 30px;
}

.subtitle {
  width: 100%;
  min-height: 25vh;
  padding-bottom: 5vh;
  padding-left: 4vw;
  padding-right: 4vw;
  color: grey;
  font-size: 24px;
}

.learn-more-div {
  width: 100%;
  margin-top: 2vh;
  height: 8vh;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}

.learn-more {
  background-color: #253551;
  width: 25%;
  min-width: fit-content;
  height: 8vh;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
  border-radius: 4px;
}

.learn-more-div a {
  font-family: Tahoma, "Helvetica Neue", Arial, sans-serif;
  font-size: 22px;
  color: white;
  text-decoration: none;
  padding: 10px;
}

.learn-more:hover {
  background-color: rgba(37, 53, 81, 0.7);
}

.picture-intern {
  width: 40%;
  height: 62vh;
  display: flex;
  justify-content: center;
}

.dem-pic {
  margin-top: 6vh;
  height: 46vh;
  width: auto;
  border: #000 3px solid;
}

.proj-pic {
  margin-top: 6vh;
  height: 35vh;
  width: 35vw;
  border: #000 3px solid;
}

.proj-div {
  min-height: 62vh;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.picture-projects {
  width: 40%;
  height: 62vh;
  display: flex;
  justify-content: center;
}

@media (max-width: 950px) {
  .exp-div {
    flex-direction: column;
    align-items: center;
  }

  .writing {
    width: 90%;
    margin-top: 2vh;
  }

  .title {
    width: 100%;
    min-height: 5vh;
    padding-top: 0vh;
    padding-bottom: 5vh;
    padding-left: 2vw;
  }

  .subtitle {
    width: 100%;
    min-height: 20vh;
    padding-bottom: 1vh;
    padding-left: 2vw;
    padding-right: 4vw;
    color: #808080;
  }

  .learn-more-div {
    width: 100%;
    height: 11vh;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
    padding-bottom: 2vh;
  }

  .learn-more {
    background-color: #253551;
    width: 25%;
    min-width: fit-content;
    height: 8vh;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
  }

  .learn-more-div a {
    font-family: Tahoma, "Helvetica Neue", Arial, sans-serif;
    font-size: 22px;
    color: white;
    text-decoration: none;
  }

  .learn-more:hover {
    background-color: rgba(37, 53, 81, 0.7);
  }

  .picture-intern {
    width: 90%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    order: -1;
    padding-top: 3vh;
  }

  .dem-pic {
    max-height: 100%;
    width: auto;
    max-width: 100%;
    height: 100%;
    border: black 2px solid;
    margin: 0;
  }

  .proj-pic {
    max-height: 100%;
    width: auto;
    max-width: 100%;
    height: 100%;
    border: black 2px solid;
    margin: 0;
  }

  .proj-div {
    flex-direction: column;
    align-items: center;
  }

  .picture-projects {
    width: 90%;
    height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1vh;
  }
}

.quote-section {
  position: relative;
  min-height: 40vh;
  width: 100%;
  background-image: url("../../Media/Blair-Arch-Background.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quote-text {
  text-align: center;
  line-height: 1.6;
  color: rgb(255, 255, 255);
  font-weight: bold;
  font-size: 34px;
  padding: 20px;

  text-shadow: -2px -2px 0 #000, /* Left and top shadow */ 2px -2px 0 #000,
    /* Right and top shadow */ -2px 2px 0 #000,
    /* Left and bottom shadow */ 2px 2px 0 #000; /* Right and bottom shadow */
}

@media (max-height: 600px) {
  .quote-text {
    font-size: 24px;
  }
}

@media (max-width: 400px) {
  .quote-text {
    font-size: 24px;
  }
}

.contact-container {
  position: relative;
  min-height: 20vh;
  width: 100%;
  background-color: white;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.get-in-touch {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gt-text {
  word-wrap: break-word;
  overflow-wrap: break-word;
  text-align: center;
  line-height: 1.6;
  color: black;
  font-weight: normal;
  padding-top: 5vh;
  font-size: 35px;
  padding-bottom: 20vh;
  height: 100%;
  width: 100%;
}

.contact-list {
  padding-top: 4vh;
  list-style: none;
  line-height: 3.2;
}

.contact-list li {
  display: flex;
  align-items: center;
}

.contact-info {
  display: flex;
  align-items: center;
  padding-left: 5vw;
  padding-bottom: 3vh;
}

.contact {
  color: black;
  text-decoration: none;
}

.temp-icon {
  height: 4vh;
  width: auto;
  padding-right: 1vw;
}

.contact a {
  font-family: Tahoma, "Helvetica Neue", Arial, sans-serif;
  font-size: 22px;
  color: black;
  padding: 10px;
}

@media (max-width: 900px) {
  .contact-container {
    position: relative;
    min-height: 15vh;
    width: 100%;
    background-color: white;
    display: grid;
    grid-template-columns: none;
    grid-template-rows: 30% 70%;
  }

  .get-in-touch {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .gt-text {
    word-wrap: break-word;
    overflow-wrap: break-word;
    text-align: center;
    line-height: 1.6;
    color: black;
    font-weight: normal;
    padding-top: 2vh;
    font-size: 35px;
    padding-bottom: 0vh;
    height: 100%;
    width: 100%;
  }

  .contact-info {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 2vw;
    padding-bottom: 3vh;
  }

  .contact-list {
    text-align: center;
    padding-top: 4vh;
    list-style: none;
  }

  .contact {
    color: black;
    text-decoration: none;
  }

  .contact a {
    font-family: Tahoma, "Helvetica Neue", Arial, sans-serif;
    font-size: 22px;
    color: black;
    padding: 10px;
  }

  .temp-icon {
    height: 4vh;
    width: auto;
    padding-right: 3vw;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
