.nav-background {
  min-height: fit-content;
  height: 8vh;
  background-color: #253551;
}

@media (max-height: 780px) {
  .nav-background {
    padding-top: 1vh;
    min-height: fit-content;
    height: 10vh;
    background-color: #253551;
  }
}

@media (max-height: 700px) {
  .nav-background {
    padding-top: 2vh;
    min-height: fit-content;
    height: 12vh;
    background-color: #253551;
  }
}

.contact-me-container {
  position: relative;
  height: 80vh;
  width: 100%;
  background-color: #e0e0db;
  display: grid;
  grid-template-columns: 60% 40%;
}

.contact-me-card {
  background-color: white;
  position: absolute;
  width: 70%;
  left: 15%;
  height: 80%;
  margin-top: 5vh;
}

.contact-me-h1 {
  font-size: 40px;
  padding-top: 2vh;
  padding-bottom: 3vh;
  padding-left: 2vw;
}

.name-input-div {
  padding-top: 1vh;
  padding-left: 2vw;
  height: 10vh;
}

.input {
  margin-right: 10vw;
  width: 20vw;

  text-transform: none;
  letter-spacing: 0;
  font-family: "Avenir LT Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 16px !important;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  overflow-x: hidden;
  box-sizing: border-box;

  border-bottom: 2px solid grey;
  background-color: transparent;
  border-top: none;
  border-right: none;
  border-left: none;
}

.input:focus {
  border-bottom: 2px solid grey;
  background-color: transparent;
  border-top: none;
  border-right: none;
  border-left: none;
  outline: none;
}

.email-input-div {
  padding-left: 2vw;
  height: 10vh;
}

.message-div {
  padding-left: 2vw;
  height: 24vh;
  width: 100%;
}

.message {
  width: 64vw;
  height: 20vh;
  resize: none;
  text-transform: none;
  letter-spacing: 0;
  font-family: "Avenir LT Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 16px !important;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  overflow-x: hidden;
  box-sizing: border-box;
  padding-left: 10px;
  padding-top: 2px;
}

.submit-button-div {
  width: 100%;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submit-button {
  background-color: rgb(37, 53, 81);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 15px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s;
}

@media (max-width: 600px) {
  .contact-me-card {
    width: 90%;
    left: 5%;
  }

  .input {
    width: 32vw;
  }

  .message {
    width: 80vw;
    height: 20vh;
  }
}
