html,
body {
  background-color: white;
  position: absolute;
}

.screen {
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
}

.nav-background {
  min-height: fit-content;
  height: 8vh;
  background-color: #253551;
}

@media (max-height: 780px) {
  .nav-background {
    padding-top: 1vh;
    min-height: fit-content;
    height: 10vh;
    background-color: #253551;
  }
}

@media (max-height: 700px) {
  .nav-background {
    padding-top: 2vh;
    min-height: fit-content;
    height: 12vh;
    background-color: #253551;
  }
}

.projects-a {
  min-height: 40vh;
  background-color: white;
  width: 100%;
  display: flex;
  align-content: center;
  justify-items: center;
}

.projects-a-div {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 25% 40% 35%;
}

.projects-b-div {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 35% 40% 25%;
}

@media (min-width: 1300px) {
  .projects-a-div {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 25% 50% 25%;
  }

  .projects-b-div {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 25% 50% 25%;
  }
}

.title-a-div {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 33% 33% 34%;
}

.title-a {
  padding-top: 2vh;
  width: 100%;
  text-align: center;
  font-size: 3.5vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.skills-a {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 20px;
}

.link-div {
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.link-a {
  background-color: rgb(37, 53, 81);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 15px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s;
}

.description-div {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 2vh;
}

.description {
  margin-top: 1vh;
  padding-top: 3vh;
  padding-left: 2vh;
  padding-right: 2vh;
  text-align: left;
  font-size: 18px;
}

.img-positioning-a {
  animation: fadeIn 1.5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-height: 40vh;
}

.image-a {
  height: 80%;
}

.projects-b {
  min-height: 40vh;
  background-color: #e0e0db;
  width: 100%;
  display: flex;
  align-content: center;
  justify-items: center;
}

@media (max-width: 900px) {
  .projects-a {
    min-height: 10vh;
    background-color: white;
    width: 100%;
    display: block;
    align-content: center;
    justify-items: center;
  }

  .projects-b {
    min-height: 10vh;
    background-color: #e0e0db;
    width: 100%;
    display: block;
    align-content: center;
    justify-items: center;
  }

  .projects-a-div {
    height: 100%;
    width: 100%;
    display: block;
  }

  .projects-b-div {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .title-a-div {
    order: 1;
    height: 17vh;
    width: 100%;
    position: relative;
    display: block;
    margin-bottom: 2vh;
  }

  .title-a {
    width: 100%;
    text-align: center;
    padding-top: 2vh;
    width: 100%;
    text-align: center;
    font-size: 3.5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1vh;
  }

  .skills-a {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-bottom: 1vh;
  }

  .link-div {
    height: 5vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .link-a {
    background-color: rgb(37, 53, 81);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 15px;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    transition: background-color 0.3s;
  }

  .description-div {
    order: 2;
    position: relative;
    display: block;
    margin-top: 0vh;
    margin-bottom: 2vh;
    padding-top: 1vh;
  }

  .description {
    width: 100%;
    padding-top: 1vh;
    padding-left: 2vh;
    padding-right: 2vh;
    text-align: left;
  }

  .img-positioning-a {
    order: 3;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2vh;
  }

  .image-a {
    max-width: 100%;
    height: auto;
    max-height: 35vh;
    padding-bottom: 2vh;
  }
}
